
.pointerLeftContainer, .pointerRightContainer {
    display: flex;
    align-items: center;
}

.pointerLeft, .pointerRight {
    cursor: pointer;
    font-size: 4rem;
    opacity: .4;
    padding: 0 10px;
}

.pointerLeft:hover, .pointerRight:hover {
    text-shadow: 2px 2px 5px rgb(255, 0, 157);
    opacity: 1;
    
}

.center {
    flex: 1;
}