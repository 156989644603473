#portfolio {
  padding-top: 50px;
}

.portSlideContainer {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  height: 95%;
  width: 100vw;
  max-width: 1024px;
  justify-content: center;
  align-content: flex-start;
  padding-top: 20px;
  overflow: hidden;
}

.animated-container {
  flex: 0 30%;
  height: 30%;
  width: 200px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 550px) {
  .animated-container {
    flex: 0 1 40%;
    height: 20%;
    margin: 10px;
  }
}