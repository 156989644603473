.raise {
  z-index: 10;
}

#landing {
  background-image: linear-gradient( to bottom left, rgba(111, 197, 231, 0.801), rgba(4, 63, 156, 0.801))
  /* url(../utils/imgs/landing.jpg) */
  ;
  background-size: cover;
  background-position: center;
  position: relative;
  /*Issue with cloud animation overflowing outside of parent element. */
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.glass {
  background: rgba(255, 255, 255, 0.2);
  z-index: 1;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  backdrop-filter: blur(40px);
  border: solid 2px transparent;
  background-clip: padding-box;
  box-shadow: 10px 10px 10px rgba(46, 54, 68, 0.05);
}

h1, .heading-primary-sub, .myName {
  text-align: center;
  font-size: 4rem;
  font-weight: 300;
  z-index: 10;
}

.myName {
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.hashLink-container {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hashLink-container a {
  width: auto;
  margin: 0 auto;
}

.hashLink-container:hover .arrow {
  transform-origin: center;
  animation: rotateDown 1s;
  transition-timing-function: ease-in;
  transform: rotate(90deg);
}

.hashLink-container:not(hover) .arrow {
  transform-origin: center;
  animation: rotateUp 1s;
  transition-timing-function: linear;
  transform: rotate(0deg)
}

@keyframes rotateDown {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(100deg)
  }
  70% {
    transform: rotate(82deg)
  }
  80% {
    transform: rotate(93deg)
  }
  90% {
    transform: rotate(88deg)
  }
  100% {
    transform: rotate(90deg)
  }
}

@keyframes rotateUp {
  0% {
    transform: rotate(90deg)
  }
  50% {
    transform: rotate(-10deg)
  }
  70% {
    transform: rotate(2deg)
  }
  80% {
    transform: rotate(-3deg)
  }
  90% {
    transform: rotate(2deg)
  }
  100% {
    transform: rotate(0deg)
  }
}

@keyframes moveUpToRight {
  0% {
    height: 50%;
    width: 50%;
    transform: translate(20vw, -20vh);
  }
  75% {
    height: 15%;
    /* between 50% and 100% nothing changes, this is how to create a delay between animation iterations */
    width: 15%;
    transform: translate(115vw, -115vh);
  }
  100% {
    height: 15%;
    width: 15%;
    transform: translate(115vw, -115vh);
  }
}

@keyframes moveUpToRightSmall {
  0% {
    height:150px;
    width: 300px;
    transform: translate(20vw, -20vh);
  }
  75% {
    height: 50px;
    /* between 50% and 100% nothing changes, this is how to create a delay between animation iterations */
    width: 100px;
    transform: translate(115vw, -115vh);
  }
  100% {
    height: 50px;
    width: 100px;
    transform: translate(115vw, -115vh);
  }
}

.learn {
  display: flex;
  color: var(--main-color);
  background-color: var(--secondary-light);
  border-radius: 5px;
  font-size: 3rem;
  padding: 0 5px;
  border: 1px solid black;
  flex: 0;
  cursor: pointer;
  overflow: hidden;
}

.arrow {
  font-size: 3rem;
  margin: 0 5px;
}

.myName {
  color: var(--secondary-light);
}

.plane1 {
  right: 110vw;
  top: 100vh;
  animation: moveUpToRight 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.plane2 {
  right: 120vw;
  top: 70vh;
  animation: moveUpToRight 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
}

.plane3 {
  right: 55vw;
  top: 120vh;
  animation: moveUpToRight 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
}

.planeFlight {
  z-index: 1;
  position: absolute;
  background-image: url(../utils/imgs/pAirplane3.png);
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 600px) {

  .plane1{
    animation: moveUpToRightSmall 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .plane2{
    animation: moveUpToRightSmall 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: 1s;
  }

  .plane3 {
    animation: moveUpToRightSmall 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: .5s;
  }


  .heading-primary, .myName, .heading-primary-sub {
    font-size: 3rem;
  }
}