.modalContainer {
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    animation: transModal .3s;
    z-index: 100;
}

.modalContent {

    width: 600px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.topModal {
    height: 400px;
    display: flex;
    justify-content: center; 
    position: relative;
}

.close {
    right: 0;
    font-size: 3rem;
    opacity: .6;
    cursor: pointer;
    margin-left: auto;
}

.close:hover, .dark:hover {
    opacity: 1;
    text-shadow: 2px 2px 5px rgb(255, 0, 157);;
}

.dark {
    color: #fff;
    opacity: .7;
}

.bottomModal {
    background-color: #fff;
    border-top: 2px solid black;
    height: 50%;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 10px;
}

.projectName {
    font-size: 3rem;
}

.projectDescription {
    font-size: 1.7rem;
    color: gray;
    border-bottom: 1px solid rgba(128, 128, 128, 0.726);
}

.projectBrief {
    font-size: 1.5rem;
    padding: 5px 0;
}

.viewApp {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
}

.gotoApp {
    background-color: rgb(255, 0, 157);
    color: white;
    padding: 5px;
    font-size: 1.5rem;
    border-radius: 3px;
    margin-right: 10px;
}

@keyframes transModal {
    from {opacity: 0;}
    to {opacity: 1}
  }

@media screen and (max-width: 650px) {
    .modalContent {
        width: 90%;
    }
  }