#about {
    background-color: var(--main-color);
    clip-path: polygon(65% 0, 100% 35%, 100% 100%, 35% 100%, 0 65%, 0 0);
}

.aboutMeContainer {
    height: 100%;
    display:flex;
    flex-wrap: wrap;
    align-content: center;
    margin: 0 auto;
    color: var(--secondary-dark);
    max-width: 1024px;
}

.photo {
  display: flex;
  flex: 0 0 40%;
  justify-content: center;
  align-items: center;
}

.aboutMeHeader {
    font-weight: 600;
    color: var(--secondary-light);
}

.aboutMeSub {
    font-weight: 300;
    color: var(--secondary-light);
}

.bio {
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    flex: 1 0 60%;
    justify-content: center;
    align-items: flex-start;
    z-index: 0;
}

.bio > * {
    margin-bottom: 1rem;
}



img {
    border-radius: 50%;
    width: 75%;
    max-width: 300px;
    height: auto;
    display: block;
}

@media screen and (max-width: 1024px) {
    .photo {
        flex: 1 0 100%;
    }
    .socialLinks {
        margin: 0 auto;
    }
    .aboutMeHeader, .aboutMeSub {
        margin: 0 auto;
    }
    .bio {
        text-align: center;
    }
    .aboutMeSub {
        margin: 0 10%;
    }
  }

  @media screen and (max-width: 600px) {
    #about {
        clip-path: none;
    }
  }

 