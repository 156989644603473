
#nav {
  height: 50px;
  border-bottom: 1px solid var(--main-color);;
  background-color: var(--secondary-dark);
}
.navWrapper {
  height: 50px;
  border-bottom: 1px solid var(--main-color);;
}

.linkContainer {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

ul {
    display: flex;
    list-style-type: none;
}

li {
    height: 100%;
    padding: 0 1rem;
    
}


.fixedNavbar {
    position: fixed;
    top: 0;
    background-color: #fff;
    width: 100%;
    animation-name: moveInFromTop;
    animation-duration: .5s;
    z-index: 10;
}

.navAnchor {
 height: 100%;
 font-size: 2rem;
 display: flex;
 align-items: center;
}

.onPage {
  color: white;
}

.offPage {
  color: var(--main-color);;
}

@media screen and (max-width: 720px) {
  .linkContainer {
  width: 100%;
  }
}

@keyframes moveInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  80% {
    transform: translateY(7px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}