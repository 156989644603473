.pCard {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

/* .pCard:last-child {
   margin: 1rem auto;
} */

img {
    
    z-index: 1;
}

.projectInfo {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
    border-radius: 5px;
    font-size: .1rem;
}

.projectInfo:hover {
    opacity: .90;
    width: 100%;
    height: 100%;
    transition:  0.5s width ease; 
    transition: opacity 0.5s linear;
}

.projectInfo:not(:hover) {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.pName {
    font-size: 2.2rem;
}

.techs {
    color: rgb(70, 90, 189);
    font-size: .1rem;
    margin: 1rem 0;
}

.learnMore {
  color: var(--main-color);
  border: 1px solid rgb(255, 0, 157);
  font-size: .5rem;
  font-weight: 200;
  cursor: pointer;
  padding: .7rem;
  color: black;
  border-radius: 10px;
  text-transform: uppercase;
  margin-top: -50px;
}

.projectInfo:not(:hover) > * {
    margin-top: -50px;
    font-size: 0.1rem;
    transition: all 0.5s ease;
}

.projectInfo:hover > * {
    margin-top: 0;
    text-align: center;
    font-size: 1.6rem;
    transition: all 0.5s ease;
    
}

.techs {
  text-align: center;
}

@keyframes fadeIn { 
  0% { 
      transform: scale(.50); 
  } 
  100% { 
    transform: scale(1); 
  } 
} 


@keyframes fadeInProjectInfo {
    0% {
      opacity: 0;
    }
  
    5% {
      opacity: .5;
    }
  
    100% {
      opacity: .1;
    }
  }

  @keyframes moveDownText {
    0% {
        opacity: 0;
        transform: translateY(-50px);
      }
  
      80% {
        opacity: 1;
        transform: translateY(3px);
      }
  
      100% {
        opacity: 1;
        transform: translate(0);
      }
  }

  