#contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    clip-path: polygon(50% 23%, 100% 10%, 100% 100%, 0 100%, 0 10%);
    z-index: 1;
    position: relative;
}

.contactContainer { 
  color: var(--main-color);
  position: relative;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  padding: 2rem;
  background-color: var(--secondary-dark);
  flex: 0 0 50%;
  width: 500px;
  overflow: hidden;
}

.form-group {
    margin: 1.2rem 0;
}

.form-text {
    display: block;
    margin-top: 0.3rem;
    color: var(--secondary-dark);
}

input[type='text'],
    input[type='email'],
    input[type='textbox'],
    input[type='date'],
    select,
    textarea {
      display: block;
      width: 100%;
      padding: 0.4rem;
      font-size: 1.2rem;
      border: 1px solid #ccc;
}

.btn {
    color: var(--main-color);
    font-size: 1.5rem;
    padding: 6px 7px;
    border: 1px solid var(--main-color);
    border-radius: 1rem;
    cursor: pointer;
}

.btn:hover {
  transform: scale(.97);
}

.sendSuccessContainer {
    background-color: var(--secondary-light);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: var(--secondary-light);
    font-size: 3rem;
    width: 200px;
    height: 100px;
    border: 1px solid rgba(255, 0, 0, 0.493);
    padding: 0;
    animation-name: flyInOutHorizontal;
    animation-duration: 3s;
    z-index: 100;
}

.sendSuccess {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(255, 0, 0, 0.493);;
    padding-bottom: 20px;
    clip-path: polygon(50% 45%, 100% 0%, 100% 100%, 0 100%, 0 0%);
    z-index: 100;
}

.homeLinkContainer {
  position: absolute;
  bottom: 60px;
}

.returnHome {
  font-size: 8rem;
  width: 50px;
  color: var(--secondary-light);
  transform: rotate(-90deg);
  cursor: pointer;
}

.returnHome:hover {
    transform: rotate(-90deg);
    animation-name: pulse;
    animation: pulse 1s infinite linear;
}

@keyframes flyInOutHorizontal {
    0% {
      transform: translate(-50vw);
      opacity: .5;
    }
  
    25% {
      /* between 50% and 100% nothing changes, this is how to create a delay between animation iterations */
      transform: translate(0);
      opacity: 1;
    }

    75% {
        transform: translate(0);
        opacity: 1;
      }
  
    100% {
      transform: translate(60vw);
      opacity: 0;
    }
  }

  @keyframes pulse {
    0% {
      font-size: 8rem;
      }

      50% {
        font-size: 9rem;
        }
  
    100% {
      font-size: 8rem;
      
    }
  }
  

@media screen and (max-width: 600px) {
    .contactContainer {
        width: 90%;
    }
  }

  