.portMenu {
    flex: 0 0 100%;
    height: 5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    color: var(--main-color);
    ;
    border-bottom: 1.5px solid var(--main-color);
    ;
    border-top: 1px solid var(--main-color);
    ;
    max-width: 1024px;
    margin: 0 auto;
    overflow: hidden;
  }

  
.selected {
    color: white;
    background-color: var(--main-color);
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }
  
  .techSelection {
    font-size: 2rem;
    cursor: pointer;
    padding: 5px 15px;
  }

  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: .4s;
  }
  
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }